<ng-template #overridesPopover>
  @for (override of scheduleOverrides; track override) {
    <span class="u_block">{{ override }}</span>
  }
</ng-template>

<div>
  <div class="app-header u_margin-md-top mb-5">
    <!-- App Title -->
    <div class="app-header-title">
      <h1 class="m-0" id="title">Displays</h1>
      <display-license-counts showOnlineCount="true"></display-license-counts>
    </div>
  </div>
  <div [shown]="displays.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <p><strong>{{displays.errorMessage}}</strong></p>
    {{displays.apiError}}
  </div>
  <div class="flex-row button-toolbar-md-folded tw-mb-8">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="displays.doSearch"></search-filter>
    @if (userStateService.hasRole('da') && !(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)) {
      <div class="ml-md-3">
        <a id="displayAddButton" class="btn btn-primary btn-toolbar-wide" uiSref="apps.displays.add">
          Add Display
        </a>
      </div>
    }
    <div class="flex-row row-wrap-xs ml-md-3">
      <mat-checkbox class="mr-1" id="allCompaniesCheckbox" (ngModelChange)="toggleAllCompanies($event)" [ngModel]="allCompanies" />
      <label class="font-weight-normal u_nowrap mb-0 u_clickable" (click)="toggleAllCompanies(!allCompanies)">Show displays from all companies</label>
    </div>
  </div>
  @if (!(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)) {
    <batch-operations [listObject]="displays"></batch-operations>
    <div>
      <div class="scrollable-list horizontal-scroll border-container tw-mb-32 tw-border tw-border-solid tw-border-gray-300 tw-min-h-[auto]"
        scrollingList (scrollEndEvent)="displays.load()" (scroll)="refresh()"
        rvSpinner="displays-list-loader" [rvShowSpinner]="displays.loadingItems">
        <table id="displaysListTable" class="table">
          <thead class="table-header">
            <tr class="table-header__row">
              <th class="table-header__cell col-xs-4">
                <div class="flex-row">
                  <mat-checkbox class="mr-2" (click)="displays.selectAll()" [ngModel]="search.selectAll" />
                  <div class="u_clickable" id="tableHeaderName" (click)="displays.sortBy('name')">
                    Name
                    @if (search.sortBy === 'name') {
                      <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                    }
                  </div>
                </div>
              </th>
              @if (allCompanies) {
                <th id="tableHeaderCompanyName" class="table-header__cell">
                  Company
                </th>
              }
              <th class="table-header__cell col-xs-1 u_clickable" (click)="displays.sortBy('licensed')">
                Licensed
                @if (search.sortBy === 'licensed') {
                  <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              <th id="tableHeaderStatus" class="table-header__cell col-xs-1 u_clickable" (click)="displays.sortBy('onlineStatus')">
                Status
                @if (search.sortBy === 'onlineStatus') {
                  <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              <th id="tableHeaderLastConnection" class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('lastActivityDate')">
                Last Connection
                @if (search.sortBy === 'lastActivityDate') {
                  <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              <th class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('scheduleName')">
                Schedule
                @if (search.sortBy === 'scheduleName') {
                  <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              <th class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('changeDate')">
                Last Modified
                @if (search.sortBy === 'changeDate') {
                  <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            @for (display of displays.items.list; track display) {
              <tr class="table-body__row" [ngClass]="{ 'bg-lighter-gray' : selectedCompayId !== display.companyId }">
                <td class="table-body__cell display-name">
                  <div class="flex-row">
                    <mat-checkbox class="mr-2" (click)="displays.select(display)" [ngModel]="display.selected" />
                    <a class="u_ellipsis-md" uiSref="apps.displays.change" [uiParams]="{displayId: display.id, companyId: display.companyId}">
                      <strong>{{display.name}}</strong>
                    </a>
                  </div>
                </td>
                @if (allCompanies) {
                  <td id="companyName-{{display.id}}" class="table-body__cell"><span>{{display.companyName}}</span></td>
                }
                <td class="table-body__cell">
                  {{ getLicenseStatus(display) }}
                </td>
                <td class="table-body__cell display-status">
                  @if (displayService.showLicenseRequired(display) && display.onlineStatus !== 'not_activated') {
                    <span>
                      License Required
                    </span>
                  }
                  @else {
                    <span [ngClass]="{'text-success': display.onlineStatus === 'online', 'text-danger': display.onlineStatus === 'offline'}">
                      {{display.onlineStatus | displayStatus}}
                    </span>
                  }
                </td>
                <td class="table-body__cell">
                  @if (displayService.showLicenseRequired(display)) {
                    <span>
                      License Required
                    </span>
                  }
                  @else {
                    <span>
                      {{display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
                    </span>
                  }
                </td>
                <td class="table-body__cell display-schedule">
                  <div class="u_nowrap flex-row">
                    @if (displayService.hasSchedule(display)) {
                      <a class="u_ellipsis-md schedule-view" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: display.scheduleId, cid: display.companyId }">
                        {{display.scheduleName}}
                      </a>
                    }
                    @else {
                      <span class="u_ellipsis-md">
                        {{ display.overrideScheduleNames ? 'No Default Schedule' : 'No Schedule Selected' }}
                      </span>
                    }
                    @if (display.overrideScheduleNames) {
                      <span class="badge badge-primary u_clickable ml-2"
                        [popover]="overridesPopover"
                        (onShown)="showPopover(display.overrideScheduleNames)"
                        containerClass="madero-style"
                        outsideClick="true"
                        placement="bottom right">
                        +{{display.overrideScheduleNames.length}}
                      </span>
                    }
                  </div>
                </td>
                <td class="table-body__cell">
                  <span class="u_ellipsis-md">
                    {{display.changeDate | date:'d-MMM-yyyy h:mm a'}} by {{display.changedBy | username}}
                  </span>
                </td>
              </tr>
            }
            <!-- If no search results -->
            <tr [shown]="displays.items.list.length === 0 && search.query !== undefined">
              <td colspan="6" class="text-center">Your search didn't return any results.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
  @if (!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined) {
    <div class="border-container text-center u_margin-md-top u_margin-md-bottom">
      <div class="panel-body">
        <div class="form-group my-4">
          Add a display to start communicating better with your audience!
        </div>
        <a id="displayAddButton" class="btn btn-primary btn-toolbar-wide mb-2" uiSref="apps.displays.add">
          Add Display
        </a>
      </div>
    </div>
  }
</div>
